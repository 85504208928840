import React from "react";
import { withPrefix } from "gatsby";

export const staffEn = [
  {
    id: "rolecek",
    name: "Jan Roleček",
    nameWithTitle: "Mgr. Jan Roleček, Ph.D.",
    email: " jan.rolecek@ibot.cas.cz",
    phoneNumber: "+420 541 126 218",
    position: "head",
    description: (
      <>
        <p>
          My research is focused on the ecology of lowland ecosystems of oak
          forests and steppe grasslands in Central and Eastern Europe. Besides
          describing these ecosystems, I investigate causes of their diversity,
          which led me to study their history on different time scales. I also
          pay attention to the issues of nature conservation and management,
          both at the level of habitats and individual endangered species. My
          work includes monographic chapters about Czech oak forests in the
          compendium Vegetation of the Czech Republic, development of a widely
          used modification of the TWINSPAN classification algorithm and a
          modern reconstruction of the Holocene dynamics of plant diversity in
          Central Europe.
        </p>
        <a
          href={withPrefix("/rolecekEnCV.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "frankova",
    name: "Markéta Fránková",
    nameWithTitle: "Mgr. Markéta Fránková, Ph.D.",
    email: "marketa.frankova@ibot.cas.cz",
    phoneNumber: "+420 541 126 216 | +420 737 527 185",
    position: "deputy-head",
    description: (
      <>
        <p>
          Markéta Fránková studied Botany and Upper Secondary School Teacher
          Training in Biology at the Faculty of Science, Masaryk University in
          Brno. She completed her Ph.D. studies in Botany by defence of the
          thesis&nbsp;
          <i>
            Ecology and taxonomy of diatoms of Western Carpathian spring fens
          </i>
          &nbsp; in 2010. She works at the Institute of Botany since 2006. She
          focuses on ecology and taxonomy of diatoms in freshwater ecosystems
          and water quality evaluation based on algal indicators. She started
          with phytobenthos of oligotrophic freshwater habitats. Nowadays she
          collaborates with Mgr. Kateřina Šumberová, Ph.D. from the Departmet of
          Vegetation ekology on the research of epiphytic diatom communities in
          fishponds and wetlands on arable land. She also deals with diatoms
          from paleoecological sediments and samples from archeologic contexts.
        </p>
        <a
          href={withPrefix("/frankovaCV.doc")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "bobek",
    name: "Přemysl Bobek",
    nameWithTitle: "Mgr. Přemysl Bobek, Ph.D.",
    email: "premysl.bobek@ibot.cas.cz",
    phoneNumber: "+420 777 477 785",
    position: "researcher",
    description: (
      <>
        <p>
          Přemysl Bobek studied Biology at the Charles University in Prague,
          where he finishing his PhD study in Botany. His main research interest
          is reconstruction of long-term fire disturbance dynamics in forest
          ecosystems, detection of fire-induced vegetation changes and
          identification of driving forces on the past fire regimes. He is
          specifically interested in applying charcoal analysis on sedimentary
          and soil paleoarchives in order to determine holocene fire regimes
          i.e. past fire frequency, intensity and spatial patterns. The results
          provide better understanding of fire-vegetation-climate interactions
          and assessing the role of anthropogenic influences. This knowledge
          also serves as a baseline for predicting future fire regimes under the
          ongoing climate change. He also focuses on other areas such as
          applying spatially explicit modeling approaches in palaeoecology.
        </p>
        <a
          href={withPrefix("/bobekCV.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "dudova",
    name: "Lydie Dudová",
    nameWithTitle: "Mgr. Lydie Dudová, Ph.D.",
    email: "lydie.dudova@ibot.cas.cz",
    phoneNumber: "+420 541 126 223",
    position: "researcher",
    description: (
      <>
        <p>
          Lydie Dudová is interested in the history of vegetation of Central
          Europe during Late Glacial and Holocene. In her research, she aims to
          describe how vegetation and landscape developed under varying climate
          and human impact in the last 15 thousand years.
        </p>
        <p>
          She is a specialist in analysis of fossil pollen and non-pollen
          objects deposited in organic sediments. She uses depth-age modelling
          and soil analyses to better interpret fossil pollen data and to
          reconstruct past vegetation of various sites, mostly in Moravia and
          Silesia. Using numerical methods, she synthesises available fossil
          pollen data to track main trends of vegetation development on a larger
          spatial scale.
        </p>
        <p>
          Lydie Dudová studied Botany at Masaryk University in Brno, Czech
          Republic. In 2016 she defended her dissertation thesis titled
          Vegetation history of the Jeseníky Mts on local and regional scale and
          attained PhD degree. Since 2007 she has worked at the Institute of
          Botany ASCR and cooperated with Institute of Botany and Zoology,
          Masaryk University. She have participated on one European Research
          Council project (Long-term Woodland Dynamics in Central Europe) and on
          five basic research projects funded by the Czech Science Foundation.
        </p>
        <a
          href={withPrefix("/dudovaCV.docx")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "hajkova",
    name: "Petra Hájková",
    nameWithTitle: "Mgr. Petra Hájková, Ph.D.",
    email: "buriana@sci.muni.cz",
    phoneNumber: "+420 541 126 223",
    position: "researcher",
    description: (
      <>
        <p>
          Petra Hájková studied Plant Systematics and Ecology in Palacký
          University, Olomouc, and was graduated in 1999. Her master thesis was
          devoted to bryoflora and plant communities of fens and fen meadows in
          the Hostýnské vrchy Mts. She continued as postgradual student in
          Masaryk University, Brno. Her PhD thesis concerned ecology and
          palaeoecology of wetlands in the Western Carpathians, and was defended
          in 2005. Presently, she is a research assistant in Masaryk University,
          Brno and in Institute of Botany, Czech Academy of Sciences. Her
          research interests are ecology and palaeoecology of mires, springs and
          wet meadows in the Western Carpathians. A special attention is paid to
          bryophytes. In the past she also studied wetlands in Bulgaria (roky?)
          and participated in research of relic ecosystems in Siberia (Russia).
        </p>
        <a
          href={withPrefix("/hajkovaCV.doc")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "hoskova",
    name: "Kristýna Hošková",
    nameWithTitle: "Mgr. Kristýna Hošková",
    email: "kristyna.hoskova@ibot.cas.cz",
    phoneNumber: "",
    position: "phd",
    description: (
      <>
        <p>
          I am PhD. student at the Department of Botany of the Charles
          University in Prague. In my thesis, I focus on modern approaches to be
          used in phytolith analysis to solve problems in paleoecology.
          Phytoliths are microscopic silica particles precipitated in plant
          tissues. Their taxonomic relevance still remain unclear. Similarly, it
          is not well understood how environmental conditions influence their
          shape. I use geometric morphometric techniques to analyse shape of
          phytoliths and disentangle these relationships. Currently, I apply
          phytolith analysis to test the Holocene continuity of species-rich
          forest-steppe ecosystems within&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://ekolbrno.ibot.cas.cz/en/biodiverzita-historie-disturbanci-a-pudni-pamet-testovani-holocenni-kontinuity-druhove-bohatych-lesostepnich-ekosystemu/"
          >
            project of my colleagues
          </a>
          &nbsp; (Jan Roleček et al.).
        </p>
      </>
    ),
  },
  {
    id: "jamrichova",
    name: "Eva Jamrichová",
    nameWithTitle: "RNDr. Eva Jamrichová, Ph.D.",
    email: "eva.jamrichova@ibot.cas.cz",
    phoneNumber: "+420 541 126 223",
    position: "researcher",
    description: (
      <>
        <p>
          Eva Jamrichová studied biology-chemistry at the University of
          Constantine the Philosopher in Nitra (UKF, Nitra, Slovakia), which she
          completed in 2005. In her subsequent postgraduate studies at UKF in
          Nitra, she focused on the relationship between prehistoric settlement
          and the natural environment in southwestern Slovakia. In 2016, she
          defended her PhD at Charles University in Prague and the topic of her
          dissertation was Anthropogenic influence on changes in the landscape
          and vegetation reflected in the pollen spectra of Central Europe. She
          currently works in the Department of Paleoecology of the Academy of
          Sciences of the Czech Republic in Brno, where she investigates changes
          in the landscape and vegetation over the last 15,000 years in the
          northern part of the Pannonian Plain and the Western Carpathians, and
          identifies human influence on these changes.
        </p>
        <a
          href={withPrefix("/jamrichovaCV.docx")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "jankovska",
    name: "Vlasta Jankovská",
    nameWithTitle: "RNDr. Vlasta Jankovská, CSc.",
    email: " vlasta.jankovska@ibot.cas.cz",
    phoneNumber: "+420 541 126 216",
    position: "researcher",
    description: (
      <>
        <p>
          Vlasta Jankovská graduated in Biology-Chemistry (Charles University,
          Prague) in 1963 and obtained her CSc (former version of PhD) in 1968
          at the Institute of Botany of the Academy of Sciences (Průhonice) and
          RNDr. at the Masaryk University in Brno (previously the University of
          Jan Evangelista Purkyně) in 1976. Since 1963 she has been working at
          the Institute of Botany of the Academy of Sciences in Brno.
        </p>
        <p>
          Her main field of interest lies in palaeoecology and its various
          methods and applications, i. e. pollen and macrofossil analysis,
          non-pollen palynomorphs analysis, palaeoalgology, palaeoreconstruction
          of vegetation and landscape since the Middle Pleistocen up to the Last
          Glacial and Holocene, including the environments of Pleistocene
          megafauna and human society. Apart form Middle Europe, she carried out
          research in the Artic and Sub-arctic regions of Scandinavia and Russia
          and successfully cooperates with historians, archaeologists,
          foresters, geologists, geomorphologists and climatologists. Nowadays,
          she focuses on publishing the plentiful results of her work.
        </p>
        <a
          href={withPrefix("/jankovskaCV.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "solcova",
    name: "Anna Šolcová",
    nameWithTitle: "Mgr. Anna Šolcová",
    email: "annapotuckova6@gmail.com",
    phoneNumber: "+420 739 092 275",
    position: "phd",
    description: (
      <>
        <p>
          Anna Šolcová is a PhD student at the Department of Botany of the
          Charles University in Prague and works at the Department of
          Paleoecology of the Botanical Institute of the Czech Academy of
          Sciences. In her PhD thesis, she focuses on investigation of lake
          sediments using macrofossil analysis. She deals with the
          reconstruction of the long-term vegetation succession during the Late
          Glacial and Holocene in the southwest Slovakia and the southeast Czech
          Republic.
        </p>
        <a
          href={withPrefix("/solcovaCV.docx")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "soukupova",
    name: "Markéta Gabriela Soukupová",
    nameWithTitle: "Mgr. Markéta Gabriela Soukupová",
    email: "Marketa.Svarcova@ibot.cas.cz",
    phoneNumber: "+420 271 015 420",
    position: "phd",
    description: (
      <>
        <p>
          Markéta G. Soukupová is pollen analyst interested in postglacial
          vegetation development, particularly in unveiling its dynamics and
          disentangling factors that determined it, such as climate, fires and
          humans. She joined the Institute of Botany in 2013. She received her
          MSc. in 2012 at the Department of Botany, Charles University in
          Prague, and defended her Master thesis “Postglacial vegetation history
          of local phenomena in western Bohemia'', focused mainly on the
          Slavkovský les protected area and its so called relict pine woods on
          serpentine subsoil and also on the migration succession of the main
          woodland taxa in western Bohemia. Being a postgraduate student at the
          same department she analyzed sedimentary cores of Bohemian-Moravian
          Highlands mirroring mostly the older half of the Holocene, Bohemian
          Switzerland and Beskyds Mts. Recently, she works on pollen monitoring
          and its long term data analyzes and she participates in a renewal of
          the Collection of permanent pollen slides of the Institute of Botany.
        </p>
        <a
          href={withPrefix("/soukupovaCV.docx")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "svitavska",
    name: "Helena Svitavská Svobodová",
    nameWithTitle: "PhDr. Helena Svitavská Svobodová, CSc.",
    email: "helena.svitavska@ibot.cas.cz",
    phoneNumber: "+420 271 015 427 |  +420 271 015 334",
    position: "researcher",
    wosResearchId: "D-4161-2013",
    links: [
      "https://www.researchgate.net/profile/Helena_Svitavska_Svobodova",
      "https://publons.com/researcher/2657470/helena-svobodova-svitavska/",
      "https://orcid.org/0000-0001-6092-4588",
    ],
    description: (
      <>
        <p>
          Helena Svitavská Svobodová deals with Quaternary palynology at the
          Institute of Botany. Her scientific research focuses on the
          development of vegetation according to pollen records from peat cores
          and from the lakes of South Moravia, Šumava and the Giant Mountains,
          dated to the last 15,000 years, and on the palynology of Pleistocene
          long cores from Massif Central in France. In recent years, she has
          been engaged in pollen analyzes from sandstone areas in Northern
          Bohemia. In 1998, she launched the annual pollen monitoring on border
          mountains in the Czech Republic, which was supported palynological
          community by the Initiative of International Union for Quaternary
          Research.
        </p>
        <a
          href={withPrefix("/svitavskaCV.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - download
        </a>
      </>
    ),
  },
  {
    id: "rybnickovi",
    name: "Eliška Rybníčková and Kamil Rybníček",
    nameWithTitle:
      "RNDr. Eliška Rybníčková, DrSc. and RNDr. Kamil Rybníček, CSc.",
    email: "",
    phoneNumber: "",
    position: "researcher",
    description: (
      <>
        <p>
          In 1962, RNDr. Eliška Rybníčková, DrSc. and RNDr. Kamil Rybnicek, CSc.
          founded a paleobotanical working group under the Department of
          Ecology, the Institute of Botany of the Czechoslovak Academy of
          Sciences, with a workplace in Brno. Research on the vegetation
          development of ecosystems since the Late Glacial and in the Holocene
          has been concentrated here, using methods of pollen analysis and
          analysis of plant macro-remains (leaf tissues, broophytes and seeds of
          vascular plants). During vegetation mapping of the Czech lands the
          department collected and gradually examined by paleobotanical methods
          400 peat profiles. Interesting publications and monographs on the
          development of mountain and lowland peat bogs attracted the general
          European paleoecological public and deepened the scientific prestige
          of the then small paleoecological working group. Paleoecological
          projects were included into international scientific research programs
          and close cooperation was established with experts mainly from Poland,
          Germany and Scandinavia. In the 1990s, paleoecological research from
          Czechoslovakia was digitized and incorporated into the European Pollen
          Database, which is now accessible on the NEOTOMA website.)
        </p>
        <p>
          The Rybníčeks actively participated in a number of paleoecological
          projects obtained from the Grant Agency of the Czech Republic. They
          were also active at Masaryk University in Brno, where they gained a
          number of students and lead their diploma theses. From 2005 to 2015,
          already at the age of emeritus, they provided teaching and
          paleoecological consultations for the Department of Vegetation
          Ecology, the Institute of Botany of the CAS and for the Institute of
          Botany and Zoology, Masaryk University in Brno. Their forty years of
          experience and practice have brought a number of scientific aspirants
          to the Institute of Botany; Vlasta Jankovská, Marie Peichlová, Helena
          Svitavská Svobodová; who have been or are scientists in the current
          Department of Paleoecology. From the younger generation, their
          followers are Lydia Dudová, Petra Hájková and Michal Hájek.
        </p>
        <p>
          We consider RNDr. Eliška Rybníčková, DrSc. and RNDr. Kamil Rybnicek,
          CSc. to be the founders of Quaternary palynology and paleoecology in
          our country. The establishment of the current Department of
          Paleoecology is the logical outcome of their scientific activities and
          the education of the younger generation of Czech paleoecologists.)
        </p>
      </>
    ),
  },
  {
    id: "formankova",
    name: "Zuzana Formánková",
    email: "zuzana.formankova@ibot.cas.cz",
    position: "techadm",
    phoneNumber: "+420 541 126 217",
  },
  {
    id: "jirouskova",
    name: "Jana Jiroušková",
    email: "jana.bozkova@atlas.cz",
    position: "techadm",
    phoneNumber: "+420 541 126 223",
  },
  {
    id: "krauseova",
    name: "Eva Krauseová",
    email: "eva.krauseova@ibot.cas.cz",
    position: "techadm",
    phoneNumber: "+420 271 015 427 | +420 271 015 334",
  },
  {
    id: "krausova",
    name: "Irena Krausová",
    email: "irena.krausova@ibot.cas.cz",
    position: "techadm",
    phoneNumber: "+420 271 015 334",
  },
  {
    id: "sadilkova",
    name: "Martina Sadílková (secretary)",
    email: "martina.sadilkova@ibot.cas.cz",
    position: "techadm",
    phoneNumber: "+420 530 506 742 | +420 541 126 231",
  },
];

export const staffCz = [
    {
    id: "rolecek",
    name: "Jan Roleček",
    nameWithTitle: "Mgr. Jan Roleček, Ph.D.",
    email: " jan.rolecek@ibot.cas.cz",
    phoneNumber: "+420 541 126 218",
    position: "head",
    description: (
      <>
        <p>
          Můj výzkum je zaměřen na ekologii nížinných ekosystémů dubových lesů a
          stepních luk ve střední a východní Evropě. Kromě jejich popisu zkoumám
          příčiny jejich rozmanitosti, což mě vedlo ke studiu jejich historie na
          různých časových škálách. Věnuji se i otázkám ochrany a managementu
          přírody, a to jak na úrovni biotopů, tak vybraných ohrožených druhů.
          Mimo jiné jsem monograficky zpracoval české dubové lesy pro kompendium
          Vegetace České republiky, podílel jsem se na vývoji široce využívané
          modifikace klasifikačního algoritmu TWINSPAN a nejnověji jsem vedl
          projekt, jehož výsledkem je moderní rekonstrukce holocenního vývoje
          druhové bohatosti rostlin ve střední Evropě.
        </p>
        <a
          href={withPrefix("/rolecekCV.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "frankova",
    name: "Markéta Fránková",
    nameWithTitle: "Mgr. Markéta Fránková, Ph.D.",
    email: "marketa.frankova@ibot.cas.cz",
    phoneNumber: "+420 541 126 216 | +420 737 527 185",
    position: "deputy-head",
    description: (
      <>
        <p>
          Markéta Fránková vystudovala Systematickou biologii a ekologii se
          zaměřením na botaniku a Učitelství biologie pro střední školy na Přf
          MU v Brně. Doktorské studium ukončila v roce 2010 obhájením práce
          <i>
            &nbsp; Ecology and taxonomy of diatoms of Western Carpathian spring
            fens&nbsp;
          </i>
          (Ekologie a taxonomie rozsivek západokarpatských pramenišť).
          Zaměstnankyní Botanického ústavu je od roku 2006. Věnuje se ekologii a
          taxonomii rozsivek ve sladkovodních biotopech a hodnocení kvality vod
          na základě řasových indikátorů. Zpočátku se zabývala fytobentosem
          oligotrofních tekoucích vod. V současnosti se ve spolupráci s Mgr.
          Kateřinou Šumberovou, Ph.D. z Oddělení vegetační ekologie věnuje
          výzkumu epifytických společenstev rozsivek na vodních a mokřadních
          rostlinách v rybnících a polních mokřadech. Zabývá se rovněž
          diatomovou analýzou paleoekologických profilů a vzorky z
          archeologických kontextů.
        </p>
        <a
          href={withPrefix("/frankovaCV.doc")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "bobek",
    name: "Přemysl Bobek",
    nameWithTitle: "Mgr. Přemysl Bobek, Ph.D.",
    email: "premysl.bobek@ibot.cas.cz",
    phoneNumber: "+420 777 477 785",
    position: "researcher",
    description: (
      <>
        <p>
          Přemysl Bobek Vystudoval odbornou biologii na Přírodovědecké fakultě
          University Karlovy v Praze, kde v současnosti na Katedře botaniky
          dokončuje postgraduální studium botaniky. Zabývá se vlivem požárových
          disturbancí na dlouhodobý vývoj lesních ekosystémů, detekcí požárem
          indukovaných vegetačních změn a hledání faktorů ovlivňujících
          požárovou dynamiku v průběhu Holocénu. Využívá především metody
          analýzy uhlíků uchovaných v sedimentárním záznamu či půdním prostředí
          k odvození historického požárového režimu, tedy frekvence, intenzity a
          prostorové struktury dřívějších požárů. Výsledky slouží k pochopení
          vzájemných interakcí mezi požáry, klimatem, vegetací a člověkem v
          postglaciálu, avšak jsou také důležitým předpokladem pro zhodnocení
          možných trajektorií vývoje pod vlivem měnícího se klimatu. Dále se
          zaměřuje na aplikaci metod prostorového modelování v paleoekologickém
          výzkumu, především prediktivního modelování výskytu druhů.
        </p>
        <a
          href={withPrefix("/bobekCV.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "dudova",
    name: "Lydie Dudová",
    nameWithTitle: "Mgr. Lydie Dudová, Ph.D.",
    email: "lydie.dudova@ibot.cas.cz",
    phoneNumber: "+420 541 126 223",
    position: "researcher",
    description: (
      <>
        <p>
          Lydie Dudová se zabývá historií vegetace střední Evropy během pozdního
          glaciálu a holocénu. Cílem jejího výzkumu je popsat, jak se vyvíjela
          vegetace a krajina pod vlivem měnícího se klimatu a zásahů člověka
          během posledních 15 tisíc let.
        </p>
        <p>
          Specializuje se na analýzu fosilního pylu a nepylových objektů z
          organických sedimentů. Pro přesnější interpretaci fosilních pylových
          dat z různých lokalit využívá depth-age modelování a analýzu půdních
          vlastností sedimentu. Numerickou analýzou dostupných pylových dat
          zkoumá hlavní trendy vývoje vegetace na větší prostorové škále.
        </p>
        <p>
          Lydie Dudová studovala obor Botanika na Masarykově univerzitě v Brně.
          V roce 2016 obhájila dizertaci s názvem Historie vegetace Jeseníků na
          lokální a regionální škále a získala titul PhD. Od roku 2007 pracuje
          na Botanickém ústavu AV ČR a spolupracuje s Ústavem botaniky a
          zoologie Masarykovy univerzity. Podílela se na projektu ERC (Long-term
          Woodland Dynamics in Central Europe) a na pěti dalších projektech
          základního výzkumu podpořených Grantovou agenturou ČR.
        </p>
        <a
          href={withPrefix("/dudovaCV.docx")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "hajkova",
    name: "Petra Hájková",
    nameWithTitle: "Mgr. Petra Hájková, Ph.D.",
    email: "buriana@sci.muni.cz",
    phoneNumber: "+420 541 126 223",
    position: "researcher",
    description: (
      <>
        <p>
          Petra Hájková studovala obor Systematická biologie a ekologie na
          Přírodovědecké fakultě Univerzity Palackého v Olomouci. Její diplomová
          práce se týkala rostlinných společenstev slatinišť a mokřadních luk a
          jejich bryoflory. Ve studiu pokračovala jako postgraduální student na
          Katedře botaniky PřF MU v Brně. Její disertační práce se týkala
          ekologie a palaeoekologie mokřadní vegetace v Západních Karpatech a
          byla obhájena v únoru 2005. V současné době je odborným pracovníkem na
          PřF MU v Brně a na Botanickém ústavu Akademie Věd ČR. Ve svém výzkumu
          se zaměřuje především na ekologii a paleoekologii rašelinišť,
          pramenišť a mokrých luk v Západních Karpatech. Zvláštní pozornost
          věnuje mechorostům. V minulosti také studovala mokřadní společenstva v
          Bulharsku a podílela se na studiu reliktních ekosystémů na Sibiři
          (Rusko).
        </p>
        <a
          href={withPrefix("/hajkovaCV.doc")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "hoskova",
    name: "Kristýna Hošková",
    nameWithTitle: "Mgr. Kristýna Hošková",
    email: " kristyna.hoskova@ibot.cas.cz",
    phoneNumber: "",
    position: "phd",
    description: (
      <>
        <p>
          Ve své disertační práci, v rámci doktorského studia na katedře
          botaniky PřF UK v Praze, se zabývám rozvojem možností fytolitové
          analýzy směrem k řešení paleoekologických otázek. Fytolity jsou
          mikroskopické křemičité částice vznikající v buňkách rostlin s dosud
          ne zcela jasnými taxonomickými a ekologickými vazbami. Těch se snažím
          dobrat analýzou tvaru fytolitů pomocí moderních technik (geometrické
          morfometriky). Všechny doposud nabyté vědomosti o fytolitech se
          aktuálně chystám uplatnit při testování kontinuity druhově bohatých
          lesostepních ekosystémů&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://ekolbrno.ibot.cas.cz/biodiverzita-historie-disturbanci-a-pudni-pamet-testovani-holocenni-kontinuity-druhove-bohatych-lesostepnich-ekosystemu/"
          >
            v rámci projektu GAČR Jana Rolečka a kol.
          </a>
        </p>
      </>
    ),
  },
  {
    id: "jamrichova",
    name: "Eva Jamrichová",
    nameWithTitle: "RNDr. Eva Jamrichová, Ph.D.",
    email: "eva.jamrichova@ibot.cas.cz",
    phoneNumber: "+420 541 126 223",
    position: "researcher",
    description: (
      <>
        <p>
          Eva Jamrichová vystudovala obor biologie-chemie na Univerzitě
          Konštantína Filozofa v Nitře (UKF, Nitra, Slovensko), který ukončila v
          roce 2005. V následném postgraduálním studiu na UKF v Nitře se
          věnovala vztahům mezi pravěkým osídlením a přírodním prostředím na
          jihozápadním Slovensku. V roce 2016 obhájila PhD na Univerzitě Karlově
          v Praze a téma její dizertační práce bylo Antropogenní vliv na změny
          krajiny a vegetace odrážející se v pylových spektrech střední Evropy.
          V současnosti pracuje v Oddělení paleoekologie BÚ AV ČR v Brně, kde se
          věnuje sledování změn krajiny a vegetace za posledních 15 tisíc let v
          severní části Panonské nížiny a Západních Karpatech, a na identifikaci
          vlivu člověka na tyto změny.
        </p>
        <a
          href={withPrefix("/jamrichovaCV.docx")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "jankovska",
    name: "Vlasta Jankovská",
    nameWithTitle: "RNDr. Vlasta Jankovská, CSc.",
    email: " vlasta.jankovska@ibot.cas.cz",
    phoneNumber: "+420 541 126 216",
    position: "researcher",
    description: (
      <>
        <p>
          Vlasta Jankovská ukončila studium oboru Biologie-Chemie na
          Přírodovědecké fakultě Karlovy Univerzity v Praze v roce 1963 jako
          Prom.biolog. Postgraduální studium na Botanickém ústavu ČSAV,
          Průhonice, zakončila jako CSc (PhD) v roce 1968. Titul RNDr získala na
          Univerzitě J. E. Purkyně v Brně (dnes Masarykova univerzita) v roce
          1976. Od roku 1963 pracuje stále na stejném pracovišti (nyní Botanický
          ústav AV ČR), přestože se v průběhu jeho existence měnil jeho název.
        </p>
        <p>
          Její specializací je paleoekologie, v níž se zaměřuje na četné
          disciplíny (pylová a makroskopická analýza, analýza nepylových forem –
          Non-Pollen-Palynomorphs-cf. Tardigrada, paleoalgologie,
          paleorekonstrukce vegetace a krajiny od středního pleistocénu –
          komplex Saalského zalednění – po poslední dobu ledovou a celý
          holocén). V rámci svého široce zaměřeného výzkumu bádala například ve
          vysoké Arktidě, v sub-arkických oblastech Skandinávie a Euroasie
          Ruska. Dále se zabývá paleoekologií pro potřeby archeologů a
          historiků, zejména obdobím od ranného středověku až po novověk.
          Lesníkům předkládá paleobotanické podklady k historii jednotlivých
          dřevin a jejich porostů, rekonstruuje životní prostředí velké
          glaciální fauny i člověka, spolupracuje s geomorfology, geology a
          klimatology. V současnosti se snaží své bohaté výsledky zpracovat do
          publikací.
        </p>
        <a
          href={withPrefix("/jankovskaCV.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "solcova",
    name: "Anna Šolcová",
    nameWithTitle: "Mgr. Anna Šolcová",
    email: "annapotuckova6@gmail.com",
    phoneNumber: "+420 739 092 275",
    position: "phd",
    description: (
      <>
        <p>
          Anna Šolcová je doktorandkou na Katedře botaniky Univerzity Karlovy v
          Praze a pracuje na katedře paleoekologie Botanického ústavu Akademie
          věd ČR. Ve své disertační práci se zaměřuje na výzkum jezerních
          sedimentů pomocí makrofosilní analýzy. Zabývá se rekonstrukcí
          dlouhodobé vegetační sukcese během pozdního glaciálu a holocénu na
          jihozápadním Slovensku a na jihovýchodě České republiky.
        </p>
        <a
          href={withPrefix("/solcovaCV.docx")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "soukupova",
    name: "Markéta G. Soukupová",
    nameWithTitle: "Mgr. Markéta Gabriela Soukupová",
    email: "Marketa.Svarcova@ibot.cas.cz",
    phoneNumber: "+420 271 015 420",
    position: "phd",
    description: (
      <>
        <p>
          Markéta G. Soukupová se zabývá pylovou analýzou - určuje pylová zrna a
          jiné objekty v pylových preparátech pocházejících ze vzorků, které
          byly odebrané z rašelinných profilů. Zajímá ji vývoj vegetace během
          holocénu, tj. od poslední doby ledové, zejména jeho dynamika, jako
          jsou vztahy mezi hlavními dřevinami a sledování faktorů, které na ně
          měly největší vliv (klima, požáry, člověk). Zaměstnankyní BÚ AV ČR je
          od roku 2013. Magisterskou práci obhájila v roce 2012 na Katedře
          botaniky, Univerzity Karlovy v Praze, na téma Postglaciální historie
          lokálních fenoménů horské vegetace západních Čech, ve které se
          zabývala zejména územím Slavkovského lesa - historií tzv. reliktních
          hadcových borů, a postupem migrace dřevin v západní části Čech. V
          rámci doktorského studia Botaniky pracovala na profilech z území
          Českomoravské vrchoviny, Českého Švýcarska a Beskyd, věnuje se také
          pylovému monitoringu a analýze dlouhodobých dat a podílí se na obnově
          sbírky pylových preparátů Botanického ústavu AV ČR.
        </p>
        <a
          href={withPrefix("/soukupovaCV.docx")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "svitavska",
    name: "Helena Svitavská Svobodová",
    nameWithTitle: "PhDr. Helena Svitavská Svobodová, CSc.",
    email: "helena.svitavska@ibot.cas.cz",
    position: "researcher",
    phoneNumber: "+420 271 015 427 |  +420 271 015 334",
    wosResearchId: "D-4161-2013",
    links: [
      "https://www.researchgate.net/profile/Helena_Svitavska_Svobodova",
      "https://publons.com/researcher/2657470/helena-svobodova-svitavska/",
      "https://orcid.org/0000-0001-6092-4588",
    ],
    description: (
      <>
        <p>
          Helena Svitavská Svobodová se v Botanickém ústavu zabývá kvartérní
          palynologií. Její vědecký výzkum je zaměřen na vývoj vegetace podle
          pylových záznamů z rašelinných vrtů a z jezer jižní Moravy, ze Šumavy
          a z Krkonoš, datovaných do posledních 15 tisíc let, a na palynologii
          pleistocenních hlubokých vrtů z maarů Massif Central ve Francii. V
          posledních letech se věnuje pylovým analýzám z pískovcových oblastí v
          severních Čechách. V roce 1998 založila roční pylové monitorování
          příhraničních hor v Čechách, které bylo podporováno palynologickou
          iniciativou v <i>International Union for Quaternary Research</i> .
        </p>
        <a
          href={withPrefix("/svitavskaCV.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          CV - ke stažení
        </a>
      </>
    ),
  },
  {
    id: "rybnickovi",
    name: "Eliška Rybníčková a Kamil Rybníček",
    nameWithTitle:
      "RNDr. Eliška Rybníčková, DrSc. a RNDr. Kamil Rybníček, CSc.",
    email: "",
    phoneNumber: "",
    position: "researcher",
    description: (
      <>
        <p>
          V roce 1962 vytvořili RNDr. Eliška Rybníčková, DrSc. a RNDr. Kamil
          Rybníček, CSc. paleobotanickou pracovní skupinu při Ekologickém
          oddělení Botanického ústavu tehdejší Československé akademie věd s
          pracovištěm v Brně. Zde se soustředil výzkum vegetačního vývoje
          ekosystémů od pozdního glaciálu a v holocénu s využitím metod pylové
          analýzy a analýzy rostlinných makrozbytků (pletiv listů, mechorostů a
          semen vyšších rostlin). V paleoekologickém pracovišti se tehdy díky
          vegetační mapě Československa nashromáždilo 400 rašelinných profilů,
          které byly postupně zkoumány paleobotanickými metodami. Zajímavé
          publikace a monografie o vývoji horských i nížinných rašelinišť
          zaujaly širokou evropskou paleoekologickou veřejnost a prohloubily
          vědeckou prestiž tehdejší malé paleoekologické pracovní skupiny.
          Paleoekologické projekty byly zahrnuty do mezinárodních
          vědecko-výzkumných programů a byla navázána úzká spolupráce s
          odborníky z hlavně z Polska, Německa a ze Skandinávie. V 90. letech
          byly paleoekologické výzkumy z Československa digitalizovány a
          začleněny do Evropské pylové databáze, která je nyní přístupná na
          webových stránkách NEOTOMA.
        </p>
        <p>
          Manželé Rybníčkovi se aktivně podíleli na řadě paleoekologických
          projektů získaných od Grantové agentury ČR. Věnovali se i pedagogické
          činnosti na Masarykově univerzitě v Brně, kde získali řadu studentů a
          kde vedli jejich diplomové práce. Od roku 2005 až do roku 2015, již v
          emeritním věku, zajišťovali pro Oddělení vegetační ekologie a pro
          Ústav botaniky a zoologie MU v Brně výuku a paleoekologické
          konzultace. Jejich čtyřicetiletá zkušenost a praxe přivedla do
          Botanického ústavu řadu vědeckých aspirantů; Vlasta Jankovská, Marie
          Peichlová, Helena Svitavská Svobodová; kteří byli nebo jsou v nynějším
          Oddělení paleoekologie vědeckými pracovníky. Z mladší generace jsou
          jejich následovníky Lydie Dudová, Petra Hájková a Michal Hájek.
        </p>
        <p>
          RNDr. Elišku Rybníčkovou, DrSc. a RNDr. Kamila Rybníčka pokládáme za
          zakladatele kvartérní palynologie a paleoekologie u nás. Vznik
          současného Oddělení paleoekologie je logickým vyústěním jejich vědecké
          činnosti a výchovy mladší generace českých paleoekologů.
        </p>
      </>
    ),
  },
  {
    id: "formankova",
    name: "Zuzana Formánková",
    email: "zuzana.formankova@ibot.cas.cz",
    position: "techadm",
    phoneNumber: "+420 541 126 217",
  },
  {
    id: "jirouskova",
    name: "Jana Jiroušková",
    email: "jana.bozkova@atlas.cz",
    position: "techadm",
    phoneNumber: "+420 541 126 223",
  },
  {
    id: "krauseova",
    name: "Eva Krauseová",
    email: "eva.krauseova@ibot.cas.cz",
    position: "techadm",
    phoneNumber: "+420 271 015 427 | +420 271 015 334",
  },
  {
    id: "krausova",
    name: "Irena Krausová",
    email: "irena.krausova@ibot.cas.cz",
    position: "techadm",
    phoneNumber: "+420 271 015 334",
  },
  {
    id: "sadilkova",
    name: "Martina Sadílková (sekretářka)",
    email: "martina.sadilkova@ibot.cas.cz",
    position: "techadm",
    phoneNumber: "+420 530 506 742 | +420 541 126 231",
  },
];
